<template>
	<div v-if="requestCalls > 0">
		<skeleton-loading></skeleton-loading>
	</div>
	<div v-else>
		<div class="row">
	        <div class="col-md-12">
	            <label class="form-control-label">{{ tt('level') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('level')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.deletion_level" @change="emptyField()" :placeholder="tt('choose_level')">
	                    <el-option class="select-danger" :value="dl" :label="dl" :key="dl" v-for="dl in  deletionLevel"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Client' || inputDraftListA4.deletion_level == 'Plant' || inputDraftListA4.deletion_level == 'Storage Location' || inputDraftListA4.deletion_level == 'Valuation' || inputDraftListA4.deletion_level == 'Sales'">
	            <label class="form-control-label">{{ tt('client') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('material_number')" rules="required">

	            	<el-select v-model="inputDraftListA4.material_number" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumber" :loading="inputTo.loading" @change="changeMaterialNumberA4" :disabled="form.a4.disabled">
                        <el-option label="" value=""></el-option>
                        <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
                    </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Plant' || inputDraftListA4.deletion_level == 'Storage Location'">
	            <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('plant')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.plant_code" :placeholder="tt('choose_plant')" @change="getMaterialPlant">
	                    <el-option class="select-danger" v-for="pc in  plantCode" :value="pc.plant_code" :label="pc.plant_code +' - '+pc.description" :key="pc.plant_code"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	    </div>

	    <div class="row">
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Storage Location'">
	            <label class="form-control-label">{{ tt('storage_location') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('storage_location')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.location_code" :placeholder="tt('choose_storage_location')">
	                    <el-option class="select-danger" v-for="lc in locationCode" :value="lc.location_code" :label="lc.location_code +' - '+ lc.location_description" :key="lc.location_code"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Valuation'">
	            <label class="form-control-label">{{ tt('valuation_area') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('valuation_area')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.valuation_area_code" :placeholder="tt('choose_valuation_area')" @change="getMaterialPlantValType">
	                    <el-option class="select-danger" v-for="pc in  plantCode" :value="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :key="pc.plant_code"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Valuation'">
	            <label class="form-control-label">{{ tt('valuation_type') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('valuation_type')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.valuation_type_code" :placeholder="tt('choose_valuation_type')">
	                    <el-option class="select-danger" v-for="vt in  valuationType" :value="vt.valuation_type_code" :label="vt.valuation_type_code +' - '+ vt.valuation_type_description" :key="vt.valuation_type_code"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	    </div>

	    <div class="row">
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Sales'">
	            <label class="form-control-label">{{ tt('sales_organization') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('sales_organization')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.sales_org_code" :placeholder="tt('choose_sales_organization')">
	                    <el-option class="select-danger" :value="so.sales_org_code" :label="so.sales_org_description" :key="so.sales_org_code" v-for="so in  salesOrganization"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Sales'">
	            <label class="form-control-label">{{ tt('distribution_channel') }}</label><span class="text-danger">*</span>
	            <base-input :name="tt('distribution_channel')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.distr_channel_code" :placeholder="tt('choose_distribution_channel')">
	                    <el-option class="select-danger" :value="dc.distr_channel_code" :label="dc.distr_channel_description" :key="dc.distr_channel_code" v-for="dc in  distributionChannel"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	    </div>

	    
<!-- 	    <div id="accordionMpnA4">
	        <div class="card z-depth-0 bordered">
	            <div class="card-header bg-secondary border" id="headingOneMpnA4">
	                <h5 class="pt-2 pl-2 pb-2 pr-2 mb--1">
	                    <div class="row">
	                        <div class="col-md-6">
	                            <span @click="toggleAccordion(accordion.a4.mpn.show, 'mpnA4')">{{tt('manufacturer_part_number')}}</span>
	                        </div>
	                        <div class="col-md-6 text-right">
	                            <base-button v-if="!form.a4.disabled" size="sm" type="default" @click="addMpnA4">{{ tt('add_new') }}</base-button>
	                        </div>
	                    </div>
	                </h5>
	            </div>
	            <div id="collapseMpnA4" :class="'collapse '+accordion.a4.mpn.show">
	                <div class="card-body border">
	                    <div class="table-responsive">
	                        <table class="table align-items-center table-flush table-bordered">
	                            <thead class="card-header bg-primary">
	                                <tr>
	                                    <th class="text-white">{{tt('mpn')}}</th>
	                                    <th></th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="(mpn, key) in manufacturePartNumberA4.mpn" v-bind:key="mpn.mpn">
	                                    <td><input :disabled="form.a4.disabled" class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumberA4.mpn[key]" rules="required"></td>
	                                    <td><i v-if="!form.a4.disabled" class="fa fa-trash text-danger" @click="removeMpnA4(key)"></i></td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div> -->

	    <!-- <div id="accordionEqiA4">
	        <div class="card z-depth-0 bordered">
	            <div class="card-header bg-secondary border" id="headingOneEqiA4">
	                <h5 class="pt-2 pl-2 pb-2 pr-2 mb--1">
	                    <div class="row">
	                        <div class="col-md-6">
	                            <span @click="toggleAccordion(accordion.a4.eqi.show, 'eqiA4')">{{tt('equipment_&_quantity_install')}}</span>
	                        </div>
	                        <div class="col-md-6 text-right">
	                            <base-button v-if="!form.a4.disabled" size="sm" type="default" @click="addEqiA4">{{ tt('add_new') }}</base-button>
	                        </div>
	                    </div>
	                </h5>
	            </div>
	            <div id="collapseEqiA4" :class="'collapse '+accordion.a4.eqi.show">
	                <div class="card-body border">
	                    <div class="table-responsive">
	                        <table class="table align-items-center table-flush table-bordered">
	                            <thead class="card-header bg-primary">
	                                <tr>
	                                    <th class="text-white">{{tt('equipment_code')}}</th>
	                                    <th></th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="(eqi, key) in equipmentQuantityInstallA4.equipment_code" v-bind:key="eqi.equipment_code">
	                                    <td>
	                                        <select :disabled="form.a4.disabled" class="form-control form-control-sm" v-model="equipmentQuantityInstallA4.equipment_code[key]">
	                                            <option value="">Choose Equipment Code</option>
	                                            <option :value="ec.equipment_code" v-for="ec in equipmentCode" v-bind:key="ec.equipment_code">{{ec.equipment_name}}</option>
	                                        </select>
	                                    </td>
	                                    <td><i v-if="!form.a4.disabled" class="fa fa-trash text-danger" @click="removeEqiA4(key)"></i></td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div> -->

	    <label class="form-control-label mt-4">{{ tt('reason') }} <span class="text-danger">*</span></label>
	    <base-input :name="tt('reason')" rules="required">
	        <textarea :disabled="form.a4.disabled" class="form-control" rows="5" v-model="inputDraftListA4.reason"></textarea>
	    </base-input>
	    <label class="form-control-label mt-4">{{ tt('note') }}</label>
	    <base-input :name="tt('note')">
	        <textarea :disabled="form.a4.disabled" class="form-control" rows="5" v-model="inputDraftListA4.note"></textarea>
	    </base-input>

	    <div class="float-right">
        	<base-button
	          type="primary"
	          @click="save('add')"
	          v-if="formType == 'add'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("add") }}</span>
			</base-button
	        >
	        <base-button
	          type="primary"
	          @click="save('update')"
	          v-else-if="formType == 'edit'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("edit") }}</span>
			</base-button>
        </div>

	</div>
</template>
<script>
	import {mapState} from 'vuex';
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import mcrApproval from '@/services/dashboard/mcrApproval.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import plantCode from '@/services/master/plantCode.service';
    import locationCode from '@/services/master/locationCode.service';
    import valuationType from '@/services/master/valuationType.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import equipmentCode from '@/services/master/equipmentCode.service';

    export default {        
        data() {
            return { 
            	requestCalls: 0,
                form: {
                    a4: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    btnSave: {
						onLoading: false
					}
                },
                accordion: {
                    a4: {
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                },
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                inputDraftListA4: {
                    material_number: '',
                    plant_code: '',
                    location_code: '',
                    valuation_area_code: '',
                    valuation_type_code: '',
                    sales_org_code: '',
                    distr_channel_code: '',
                    warehouse_number: '',
                    storage_type_code: '',
                    reason: '',
                    note: '',
                    deletion_level: '',
                },
                manufacturePartNumberA4: {
                    mpn: [],
                },
                equipmentQuantityInstallA4: {
                    equipment_code: [],
                },
                deletionLevel: {},
                materialNumber: [],
                plantCode: {},
                locationCode: [],
                valuationType: {},
                salesOrganization: {},
                distributionChannel: {},
                equipmentCode: {},

                mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type
            }
        },
        mounted() {
        	if (this.formType == 'edit' || this.formType == 'detail') {
				this.getDetailMcrItem();
				this.checkFormType();
			}
            this.getParameterMcr();
            this.getSalesOrganization();
            this.getDistributionChannel();
            // this.getEquipmentCode();
            this.emptyField();
        },
        methods: {
        	checkFormType() {
				switch(this.formType) {
					case 'edit':
						this.form.a4.disabled = false;
						break;
					case 'detail':
						this.form.a4.disabled = true;
						break;
				}
			},
        	getDetailMcrItem() {
        		this.requestCalls++;
                let context = this;        
                       
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {
                        context.inputDraftListA4.id = response.data.data[0].id;
                        context.inputDraftListA4.deletion_level = response.data.data[0].deletion_level;
                        context.inputDraftListA4.material_number = response.data.data[0].material_number;
                        context.inputDraftListA4.plant_code = response.data.data[0].plant_code;
                        context.inputDraftListA4.location_code = response.data.data[0].location_code;
                        context.inputDraftListA4.valuation_area_code = response.data.data[0].plant_code;
                        context.inputDraftListA4.valuation_type_code = response.data.data[0].valuation_type_code;
                        context.inputDraftListA4.sales_org_code = response.data.data[0].sales_org_code;
                        context.inputDraftListA4.distr_channel_code = response.data.data[0].distr_channel_code;
                        context.inputDraftListA4.reason = response.data.data[0].reason;
                        context.inputDraftListA4.note = response.data.data[0].note;
                        context.materialNumber[0] = {
				          	material_number: response.data.data[0].material_number,
				          	short_description: response.data.data[0].short_description,
				        };
				        context.plantCode[0] = {
				          	plant_code: response.data.data[0].plant_code,
				          	description: response.data.data[0].description,
				        };
				        context.locationCode[0] = {
				          	location_code: response.data.data[0].location_code,
				          	location_description: response.data.data[0].location_description,
				        };
				        context.valuationType[0] = {
				          	valuation_type_code: response.data.data[0].valuation_type_code,
				          	valuation_type_description: response.data.data[0].valuation_type_description,
				        };
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA4 = [];
                        }
                    }).onFinish(function(){
	                	context.requestCalls--;
	                }).call()

                    // Api(context, draftList.getMcrItemMpn(this.mcrItemCode)).onSuccess(function(response) {
                    //     for (var i=0; i<response.data.data.length; i++) {
                    //         context.manufacturePartNumberA4.mpn[i] = response.data.data[i].mpn;
                    //     }                        
                    // }).onError(function(error) {                    
                    //     if (error.response.status == 404) {
                    //         context.manufacturePartNumberA4.mpn = [];
                    //     }
                    // }).call()

                    // Api(context, draftList.getMcrItemEquipment(this.mcrItemCode)).onSuccess(function(response) {
                    //     for (var i=0; i<response.data.data.length; i++) {
                    //         context.equipmentQuantityInstallA4.equipment_code[i] = response.data.data[i].equipment_code;
                    //     }                        
                    // }).onError(function(error) {                    
                    //     if (error.response.status == 404) {
                    //         context.equipmentQuantityInstallA4.equipment_code = [];
                    //     }
                    // }).call()
            },
            changeMaterialNumberA4() {  
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA4.material_number})).onSuccess(function(response) {
                	context.plantCode = response.data.data.part_plant;

                    // for (var i=0; i<response.data.data.part_manufacture_ref.length; i++) {
                    //     context.manufacturePartNumberA4.mpn[i] = response.data.data_part_manufacture_ref.[i].manufacture_ref;
                    // }

                    // for (var i=0; i<response.data.data.part_equipment_code.length; i++) {
                    //     context.equipmentQuantityInstallA4.equipment_code[i] = response.data.data.part_equipment_code[i].equipment_code;
                    // }

                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                    	context.plantCode = [];
                        context.manufacturePartNumberA4.mpn = [];
                        context.equipmentQuantityInstallA4.equipment_code = [];
                    }
                })
                .call()
            },
            getMaterialPlant() {
                let context = this;               
                Api(context, endUserSearchMaterial.getMaterialPlant({material_number: context.inputDraftListA4.material_number, plant_code: context.inputDraftListA4.plant_code})).onSuccess(function(response) { 
       					context.locationCode = response.data.data.part_storage_location;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.locationCode = [];
                    }
                })
                .call()
            },
            getMaterialPlantValType() {
                let context = this;               
                Api(context, endUserSearchMaterial.getMaterialPlant({material_number: context.inputDraftListA4.material_number, plant_code: context.inputDraftListA4.valuation_area_code})).onSuccess(function(response) { 
       					context.valuationType = response.data.data.part_accounting;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationType = [];
                    }
                })
                .call()
            },
        	getParameterMcr() {
        		this.requestCalls++;
		    	let context = this;

		    	Api(context, draftList.getParameterMcr(this.mcrItemCode))
		        .onSuccess(function(response) {
		          context.deletionLevel = response.data.data.deletion_level;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        }).onFinish(function(){
                	context.requestCalls--;
                }).call()
		    },
		    getMaterialNumber(query) {
		    	if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumber = response.data.data;                        
                            context.inputTo.loading = false;                     
                        }).onError(function(error) {
                            context.materialNumber = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }
            },
            getSalesOrganization() {
            	this.requestCalls++;
                let context = this; 

                Api(context, salesOrganization.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                }).onFinish(function(){
                	context.requestCalls--;
                }).call()
            },
            getDistributionChannel() {
            	this.requestCalls++;
                let context = this;  

                Api(context, distributionChannel.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.distributionChannel = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.distributionChannel = [];
                    }
                }).onFinish(function(){
                	context.requestCalls--;
                }).call()
            },
            getEquipmentCode() {
                let context = this;               
                Api(context, equipmentCode.get('none', {per_page: 'none'})).onSuccess(function(response) {    
                    context.equipmentCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.equipmentCode = [];
                    }
                })
                .call()
            },
            addMpnA4() {
            	this.accordion.a4.mpn.show = 'show'

                if (this.manufacturePartNumberA4.mpn.length == 0) {
                    this.manufacturePartNumberA4.mpn.push('')
                } else if (this.manufacturePartNumberA4.mpn.length > 0) {
                    if (this.manufacturePartNumberA4.mpn[this.manufacturePartNumberA4.mpn.length-1] != '') {
                        
                        this.manufacturePartNumberA4.mpn.push('')
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("mpn") }),                
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnA4(key) {
                this.manufacturePartNumberA4.mpn.splice(key, 1)
            },
            addEqiA4() {
            	this.accordion.a4.eqi.show = 'show'

                if (this.equipmentQuantityInstallA4.equipment_code.length == 0) {
                    this.equipmentQuantityInstallA4.equipment_code.push('')
                } else if (this.equipmentQuantityInstallA4.equipment_code.length > 0) {
                    if (this.equipmentQuantityInstallA4.equipment_code[this.equipmentQuantityInstallA4.equipment_code.length-1] != '') {
                        
                        this.equipmentQuantityInstallA4.equipment_code.push('')
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", {title: this.tt("equipment_&_quantity_install")}),                
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiA4(key) {
                this.equipmentQuantityInstallA4.equipment_code.splice(key, 1)                
            },
            createFilter(queryString) {
                return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            toggleAccordion(show, type) {
                if (type == 'mpnA4') {
                    if (show == '') {
                        this.accordion.a4.mpn.show = 'show'
                    } else {
                        this.accordion.a4.mpn.show = ''
                    }
                } else if (type == 'eqiA4') {
                    if (show == '') {
                        this.accordion.a4.eqi.show = 'show'
                    } else {
                        this.accordion.a4.eqi.show = ''
                    }
                }
            },
            save(save) {
                let context = this;          
                let data = {
                    mcr_code: context.mcr_code,
                    deletion_level: context.inputDraftListA4.deletion_level,
                    reason: context.inputDraftListA4.reason,
                    note: context.inputDraftListA4.note,
                }

                if (context.inputDraftListA4.deletion_level !== '') {
                    if (context.inputDraftListA4.deletion_level == 'Client') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Plant') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.plant_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Storage Location') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.plant_code != '' && context.inputDraftListA4.location_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Valuation') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.valuation_area_code != '' && context.inputDraftListA4.reason != '' && context.inputDraftListA4.valuation_type_code != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Sales') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.sales_org_code != '' && context.inputDraftListA4.distr_channel_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                } else {
                    var validation = false;
                }

                if (validation == true) {
                	if (context.inputDraftListA4.deletion_level == 'Client') {
                		data.material_number = context.inputDraftListA4.material_number;
                	}

                	if (context.inputDraftListA4.deletion_level == 'Plant') {
                		data.material_number = context.inputDraftListA4.material_number;
                		data.plant_code = context.inputDraftListA4.plant_code;
                	}

                	if (context.inputDraftListA4.deletion_level == 'Storage Location') {
                		data.material_number = context.inputDraftListA4.material_number;
                	    data.plant_code = context.inputDraftListA4.plant_code;
                		data.location_code = context.inputDraftListA4.location_code;
                	}

                	if (context.inputDraftListA4.deletion_level == 'Valuation') {
                		data.material_number = context.inputDraftListA4.material_number;
                		data.plant_code = context.inputDraftListA4.valuation_area_code;
                		data.valuation_type_code = context.inputDraftListA4.valuation_type_code
                	}

                	if (context.inputDraftListA4.deletion_level == 'Sales') {
                		data.material_number = context.inputDraftListA4.material_number;
                		data.sales_org_code = context.inputDraftListA4.sales_org_code;
                    	data.distr_channel_code = context.inputDraftListA4.distr_channel_code;
                	}

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else if (save == 'update') {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA4.id));
                    } else if (save == "approval") {
			        	api = Api(context, mcrApproval.process(data, context.input.id));
			        }
                    
                    context.form.btnSave.onLoading = true;

                    api.onSuccess(function(response) {    
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
							horizontalAlign: 'left'
                        });
                        context.form.btnSave.onLoading = false;
                        context.$router.back();
                        // context.emptyField();
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                            context.form.btnSave.onLoading = false;
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: this.tt("please_complete_data", {title: this.tt("deletion_flag_material")}),                  
                        type: 'danger'
                    });
                }
            },
            emptyField(){
        		if (this.inputDraftListA4.deletion_level == 'Client') 
        		{
        			this.inputDraftListA4.plant_code = '';
	                this.inputDraftListA4.location_code = '';
	                this.inputDraftListA4.valuation_type_code = '';
	                this.inputDraftListA4.valuation_area_code = '';
	                this.inputDraftListA4.sales_org_code = '';
	                this.inputDraftListA4.distr_channel_code = '';
        		}else if (this.inputDraftListA4.deletion_level == 'Plant') 
        		{
        			this.inputDraftListA4.location_code = '';
	                this.inputDraftListA4.valuation_type_code = '';
	                this.inputDraftListA4.valuation_area_code = '';
	                this.inputDraftListA4.sales_org_code = '';
	                this.inputDraftListA4.distr_channel_code = '';
        		}else if (this.inputDraftListA4.deletion_level == 'Storage Location') 
        		{
        			this.inputDraftListA4.valuation_type_code = '';
	                this.inputDraftListA4.valuation_area_code = '';
	                this.inputDraftListA4.sales_org_code = '';
	                this.inputDraftListA4.distr_channel_code = '';
        		}else if (this.inputDraftListA4.deletion_level == 'Valuation') 
        		{
        			this.inputDraftListA4.plant_code = '';
        			this.inputDraftListA4.location_code = '';
	                this.inputDraftListA4.sales_org_code = '';
	                this.inputDraftListA4.distr_channel_code = '';
        		}else if (this.inputDraftListA4.deletion_level == 'Sales') 
        		{
        			this.inputDraftListA4.plant_code = '';
        			this.inputDraftListA4.location_code = '';
        			this.inputDraftListA4.valuation_type_code = '';
	                this.inputDraftListA4.valuation_area_code = '';
        		}
        	},
        }   
    };
</script>