<template>
    <div v-if="requestCalls > 0">
        <skeleton-loading></skeleton-loading>
    </div>
	<div v-else>
		<label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
        <base-input :disabled="form.disabled" :name="tt('description')" :placeholder="tt('description')" v-model="inputDraftListB1.description" rules="required"></base-input>

		<div id="accordionPrm">
	        <div class="card z-depth-0 bordered">
	          	<div class="card-header bg-secondary border" id="headingOneBds">
		            <div class="row">
		              <div class="col-md-6">
		                <h5 class="mb-0">
		                  <a
		                    href="javascript:void(0)"
		                    @click="toggleAccordion(accordion.prm.show, 'prm')"
		                    >{{ tt("parameter") }}
		                    <span class="text-danger">*</span></a
		                  >
		                </h5>
		              </div>
		            </div>
	          	</div>
	          	<div id="collapsePrm" :class="'collapse ' + accordion.prm.show">
		            <div class="card-body border">
				        <table class="table align-items-center table-flush table-bordered table-condensed table-sm">
				            <thead class="card-header bg-primary">
				                <tr>
				                    <th class="text-white">{{tt('parameter')}}</th>
                                    <th class="text-white">{{tt('category')}}</th>
				                    <th class="text-white">{{tt('value')}}</th>
                                    <th class="text-white">{{tt('description')}}</th>
                                    <th class="text-white">{{tt('attachment')}}</th>
				                    <th><base-button v-if="formType != 'detail'" size="sm" class="rounded-circle" type="default" @click="addParamsB1"><i class="fa fa-plus"></i></base-button></th>
				                </tr>
				            </thead>
				            <tbody>
                                <!-- PREVIEW -->
				                <tr v-for="(pp, key) in prevParamsB1.parameter" v-bind:key="pp">
				                    <td>
				                        <select :disabled="form.disabled" class="form-control form-control-sm" v-model="prevParamsB1.parameter[key]">
				                            <option value="">Choose Parameter</option>
				                            <option v-for="p in parameter" v-bind:key="p">{{p}}</option>
				                        </select>
				                    </td>
				                    <td>
				                        <select :disabled="form.disabled" class="form-control form-control-sm" v-model="prevParamsB1.category[key]">
				                            <option value="">Choose Category</option>
				                            <option :value="c" v-for="c in category" v-bind:key="c">{{c}}</option>
				                        </select>
				                    </td>
				                    <td>
                                        <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('value')" :placeholder="tt('value')" v-model="prevParamsB1.value[key]" rules="required">
                                    </td>
                                    <td>
                                        <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('description')" :placeholder="tt('description')" v-model="prevParamsB1.description[key]" rules="required">
                                    </td>
                                    <td v-if="formType != 'add'">
                                        <a :href="apiUrl+'smartcat_attachment/'+prevParamsB1.preview[key]" target="_blank">{{prevParamsB1.preview[key]}}</a>
                                    </td>
				                    <td v-if="formType != 'detail'">
				                    	<base-button size="sm" class="rounded-circle" type="danger" @click="removePrevAd(prevParamsB1.id[key])"><i class="fa fa-minus"></i></base-button>
				                    </td>
				                </tr>

                                <!-- INPUT -->
                                <tr v-for="(pp, key) in paramsB1.parameter" v-bind:key="pp">
                                    <td>
                                        <select :disabled="form.disabled" class="form-control form-control-sm" v-model="paramsB1.parameter[key]">
                                            <option value="">Choose Parameter</option>
                                            <option v-for="p in parameter" v-bind:key="p">{{p}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select :disabled="form.disabled" class="form-control form-control-sm" v-model="paramsB1.category[key]">
                                            <option value="">Choose Category</option>
                                            <option :value="c" v-for="c in category" v-bind:key="c">{{c}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('value')" :placeholder="tt('value')" v-model="paramsB1.value[key]" rules="required">
                                    </td>
                                    <td>
                                        <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('description')" :placeholder="tt('description')" v-model="paramsB1.description[key]" rules="required">
                                    </td>
                                    <td>
                                        <file-input
                                          :disabled="form.disabled"
                                          @change="changeFileAttachmentName"
                                          :initialLabel="pp.attachment"
                                          :ref="'file' + key"
                                          accept="application/pdf"
                                        ></file-input>
                                    </td>
                                    <td v-if="formType != 'detail'">
                                        <base-button v-if="formType == 'edit' || key != 0" size="sm" class="rounded-circle" type="danger" @click="removeParamsB1(key)"><i class="fa fa-minus"></i></base-button>
                                    </td>
                                </tr>
				            </tbody>
				        </table>
				    </div>
				</div>
			</div>
		</div>

        <label class="form-control-label mt-4">{{ tt('reason') }} <span class="text-danger">*</span></label>
        <base-input :name="tt('reason')" rules="required">
            <textarea :disabled="form.disabled" class="form-control" v-model="inputDraftListB1.reason" rows="5"></textarea>
        </base-input>
        <label class="form-control-label mt-4">{{ tt('note') }}</label>
        <base-input :name="tt('note')">
            <textarea :disabled="form.disabled" class="form-control" v-model="inputDraftListB1.note" rows="5"></textarea>
        </base-input>

        <div class="float-right">
        	<base-button
	          type="primary"
	          @click="save('add')"
	          v-if="formType == 'add'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("add") }}</span>
			</base-button
	        >
	        <base-button
	          type="primary"
	          @click="save('update')"
	          v-else-if="formType == 'edit'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("edit") }}</span>
			</base-button>
        </div>
	</div>
</template>
<script>
	import Api from '@/helpers/api';
	import draftList from '@/services/material/draftList.service';
    import baseApiUrl from '@/configs/config';

	export default {        
        data() {
            return { 
                requestCalls: 0,
            	form: {
                    btnSave: {
						onLoading: false
					}
                },
                accordion: {
                    prm: {
                        show: 'show'
                    },
                },
                inputDraftListB1: {
                    description: '',
                    reason: '',
                    note: ''
                },
                prevParamsB1: {
                    id: [],
                    parameter: [],
                    category: [],
                    value: [],
                    description: [],
                    preview: [],
                },
                paramsB1: {
                    parameter: [],
                    category: [],
                    value: [],
                    description: [],
                    attachment: [],
                },
                parameter: {},
                category: {},
                apiUrl: baseApiUrl.storageUrl,

                mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type
            }
        },
        mounted() {
        	if (this.formType == 'edit' || this.formType == 'detail') {
				this.getDetailMcrItem();
				this.checkFormType();
			}else{
                this.addParamsB1();
            }
        	this.getParameterMcr();
        },
        methods: {
        	checkFormType() {
				switch(this.formType) {
					case 'edit':
						this.form.disabled = false;
						break;
					case 'detail':
						this.form.disabled = true;
						break;
				}
			},
        	getDetailMcrItem() {
                this.requestCalls++;
        		let context = this; 
                
                // context.inputDraftListB1.description = [];
                // context.inputDraftListB1.reason      = [];
                // context.inputDraftListB1.note        = [];
                context.prevParamsB1.parameter       = [];
                context.prevParamsB1.category        = [];
                context.prevParamsB1.value           = [];
                context.prevParamsB1.description     = [];
                context.prevParamsB1.preview         = [];

                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                    context.inputDraftListB1.id          = response.data.data[0].id;
                    context.inputDraftListB1.description = response.data.data[0].specification;
                    context.inputDraftListB1.reason      = response.data.data[0].reason;
                    context.inputDraftListB1.note        = response.data.data[0].note;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.inputDraftListB1 = [];
                    }
                }).call()
                
                Api(context, draftList.getMcrItemParameter(this.mcrItemCode)).onSuccess(function(response) {
                    for (var i=0; i<response.data.data.length; i++) {
                        context.prevParamsB1.id[i]          = response.data.data[i].id;
                        context.prevParamsB1.parameter[i]   = response.data.data[i].parameter;
                        context.prevParamsB1.category[i]    = response.data.data[i].category;
                        context.prevParamsB1.value[i]       = response.data.data[i].value;
                        context.prevParamsB1.description[i] = response.data.data[i].description;
                        context.prevParamsB1.preview[i]     = response.data.data[i].attachment;
                    }                        
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.prevParamsB1.parameter   = [];
                        context.prevParamsB1.category    = [];
                        context.prevParamsB1.value       = [];
                        context.prevParamsB1.description = [];
                        context.prevParamsB1.preview     = [];
                    }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
        	getParameterMcr() {
                this.requestCalls++;
		    	let context = this;

		    	Api(context, draftList.getParameterMcr(this.mcrItemCode))
		        .onSuccess(function(response) {
		          context.parameter = response.data.data.parameter;
		          context.category = response.data.data.category;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        }).onFinish(function(){
                    context.requestCalls--;
                }).call()
		    },
        	addParamsB1() {
                this.paramsB1.parameter.push('');
                this.paramsB1.category.push('');
                this.paramsB1.value.push('');
                this.paramsB1.description.push('');
                this.paramsB1.attachment.push('');
            },
            removeParamsB1(key) {
                this.paramsB1.parameter.splice(key,1)
                this.paramsB1.category.splice(key,1)
                this.paramsB1.value.splice(key,1)
                this.paramsB1.description.splice(key,1)
                this.paramsB1.attachment.splice(key,1)
            },
            save(save) {
                let context = this;
                let formData = new FormData();
                formData.append("note", context.inputDraftListB1.note);

                if (context.inputDraftListB1.description != '' && context.inputDraftListB1.reason != '') 
                {
                    formData.append("mcr_code", context.mcr_code);
                    formData.append("specification", context.inputDraftListB1.description);
                    formData.append("reason", context.inputDraftListB1.reason);

                    // ------------------ PREV PARAMETER ------------------
                    for (var i=0; i<context.prevParamsB1.parameter.length; i++) 
                    {
                        if (context.prevParamsB1.parameter[i] != "" && context.prevParamsB1.category[i] != "" && context.prevParamsB1.value[i] != "" && context.prevParamsB1.description[i] != "") 
                        {
                            formData.append("prev_parameter[" + i + "]", context.prevParamsB1.parameter[i]);
                            formData.append("prev_category[" + i + "]", context.prevParamsB1.category[i]);
                            formData.append("prev_value[" + i + "]", context.prevParamsB1.value[i]);
                            formData.append("prev_description[" + i + "]", context.prevParamsB1.description[i]);
                            formData.append("prev_attachment[" + i + "]", context.prevParamsB1.preview[i]);
                        } else {
                            context.$notify({
                                message: this.tt("please_complete_data", { title: this.tt("parameter") }),                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    // ------------------ PARAMETER ------------------
                    for (var i=0; i<context.paramsB1.parameter.length; i++) 
                    {
                        if (context.paramsB1.parameter[i] != "" && context.paramsB1.category[i] != "" && context.paramsB1.value[i] != "" && context.paramsB1.description[i] != "" && context.paramsB1.attachment[i] != "") 
                        {
                            formData.append("parameter[" + i + "]", context.paramsB1.parameter[i]);
                            formData.append("category[" + i + "]", context.paramsB1.category[i]);
                            formData.append("value[" + i + "]", context.paramsB1.value[i]);
                            formData.append("description[" + i + "]", context.paramsB1.description[i]);
                            formData.append("attachment[" + i + "]", context.paramsB1.attachment[i]);
                        } else {
                            context.$notify({
                                message: this.tt("please_complete_data", { title: this.tt("parameter") }),                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(formData));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(formData, context.inputDraftListB1.id));
                    }
                    
                    context.form.btnSave.onLoading = true;

                    api.onSuccess(function(response) {
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                        context.form.btnSave.onLoading = false;
                        context.$router.back();
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                            context.form.btnSave.onLoading = false;
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("create_&_edit_master_cataloguing") }),                  
                        type: 'danger'
                    });
                    return false; 
                }                
            },
            toggleAccordion(show, type) {
              if (type == 'prm') {
                    if (show == '') {
                        this.accordion.prm.show = 'show'
                    } else {
                        this.accordion.prm.show = ''
                    }
                }
            },
            changeFileAttachmentName(files) {
                if (files[0].size > 2000000) {
                    alert("MAX SIZE 2 MB")
                    this.paramsB1.attachment == [];
                }else {
                    this.paramsB1.attachment[
                        this.paramsB1.attachment.length - 1
                    ] = files[0];
                }
            },
            removePrevAd(id){
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, draftList.deleteMcrItemParameter(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.getDetailMcrItem();
                        }).call();
                    }
                })
            },
        }   
    };
</script>