<template>
	<div>
		<base-header class="pb-6">
	      <div class="row align-items-center py-4">
	        <div class="col-lg-6 col-7">
	          <h6 class="h2 text-white d-inline-block mb-0"></h6>
	        </div>
	      </div>
	    </base-header>
	    <div class="container-fluid mt--6">
	      <div class="card mb-4">
	        <div class="card-header mt--4">
	          <div class="row">
	            <div class="col">
	              <h3 class="mb-0 text-center">{{ tt("mcr_form") + (formType == 'edit' ? ' EDIT' : ' DETAIL') }}</h3>
	            </div>
	          </div>
	        </div>
	        <div class="card-body">
				<McrFormA1 v-if  ="mcrType == 'A1'"></McrFormA1>
				<!-- <McrFormA2 v-if  ="mcrType == 'A2'"></McrFormA2> -->
				<McrFormA2a v-if ="mcrType == 'A2a'"></McrFormA2a>
				<McrFormA2b v-if ="mcrType == 'A2b'"></McrFormA2b>
				<McrFormA3 v-if  ="mcrType == 'A3'"></McrFormA3>
				<McrFormA4 v-if  ="mcrType == 'A4'"></McrFormA4>
				<McrFormB1 v-if  ="mcrType == 'B1'"></McrFormB1>
				<McrFormB v-if   ="mcrType == 'B2' || mcrType == 'B3a' || mcrType == 'B3b' || mcrType == 'B4'"></McrFormB>
				<McrFormB5 v-if  ="mcrType == 'B5'"></McrFormB5>
	        </div>
	      </div>
	    </div>
	</div>
</template>

<script>
	import Api from "@/helpers/api";
	import McrFormA1 from "./McrFormA1.vue";
	// import McrFormA2 from "./McrFormA2.vue";
	import McrFormA2a from "./McrFormA2a.vue";
	import McrFormA2b from "./McrFormA2b.vue";
	import McrFormA3 from "./McrFormA3.vue";
	import McrFormA4 from "./McrFormA4.vue";
	import McrFormB1 from "./McrFormB1.vue";
	import McrFormB from "./McrFormB.vue";
	import McrFormB5 from "./McrFormB5.vue";

	export default {
		components: {
	      McrFormA1,
	      // McrFormA2,
	      McrFormA2a,
	      McrFormA2b,
	      McrFormA3,
	      McrFormA4,
	      McrFormB1,
	      McrFormB,
	      McrFormB5,
	    },
	    data() {
	    	return {
	    		mcrType: this.$route.params.mcr_type,
	    		formType: this.$route.params.type
	    	}
	    },
	    mounted() {

		},
		methods: {
			
		}
	};	
</script>